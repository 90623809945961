import * as nwc from '@/wallets/nwc/client'
import * as lnbits from '@/wallets/lnbits/client'
import * as lnc from '@/wallets/lnc/client'
import * as lnAddr from '@/wallets/lightning-address/client'
import * as cln from '@/wallets/cln/client'
import * as lnd from '@/wallets/lnd/client'
import * as webln from '@/wallets/webln/client'
import * as blink from '@/wallets/blink/client'
import * as phoenixd from '@/wallets/phoenixd/client'

export default [nwc, lnbits, lnc, lnAddr, cln, lnd, webln, blink, phoenixd]
